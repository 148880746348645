import React from "react";
import "./style.css";

const WeTakeProject = () => {
  return (
    <>
      <div className="we-take-section">
        <div className="container">
          <h3 className="heading-wetake-page">
            “We take a pride in delivering each
            <br className="d-none d-md-block" /> project in given timeline with
            quality &<br className="d-none d-md-block" /> honoring our EHS
            commitment.“
          </h3>
          <h6 className="child-heading-wetake pt-5">Hitendra Kothakar</h6>
          <p className="child-parah-page">Director Project & Purchase (West)</p>
        </div>
      </div>
    </>
  );
};

export default WeTakeProject;
