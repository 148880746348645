import React from 'react'
import BannerContact from './Banner/index'
import FormValidation from './FormValidation/index'


const Careers = () => {
  return (
    <>
        <BannerContact />
        <FormValidation />
    </>
  )
}

export default Careers;
